// @flow
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { MdAddChart } from "react-icons/md";

import {
  Box,
  Tag,
  useColorMode,
  Button,
  Text,
  Tooltip,
  Icon,
  UnorderedList,
  ListItem,
  IconButton,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import messages from "./messages";
import moment from "moment";
import enums, { TRANSACTIONTYPES } from "../../utils/enums";
import SimpleModal from "../Modal";
import XpressWorkshop from "../WorkShopList/XpressCustomer";
import { useSelector } from "react-redux";
import { selectGlobalState } from "../../globalSlice";
import { UseTitleContext } from "../../App";
import { FaRegCopy } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import UpdateInvoiceModal from "../../containers/OrderDetailsNew/updateInvoiceModal";

export const OrderDetailInfo = ({
  orderDetails,
  orderNotCreated,
  fetchOrderDetails,
  parentOrderDetails,
}) => {
  const intl = useIntl();

  const { updateTitle, isTitleUpdated } = UseTitleContext();
  const { allAvailableBranches, supplier } = useSelector(selectGlobalState);
  const [isToolTipDisabled, setIsToolTipDisabled] = useState(false);
  const [openingFileTypeInvoiceModal, setOpeningFileTypeInvoiceModal] =
    useState("");
  const [
    isInvoiceNoCopiedToolTipDisabled,
    setIsInvoiceNoCopiedToolTipDisabled,
  ] = useState(false);
  const [
    isChallanNoCopiedToolTipDisabled,
    setIsChallanNoCopiedToolTipDisabled,
  ] = useState(false);
  const [
    isparentOrderNoCopiedToolTipDisabled,
    setIsParentOrderNoCopiedToolTipDisabled,
  ] = useState(false);

  const [
    isSupplierCodeCopiedToolTipDisabled,
    setIsSupplierCodeCopiedTooltipDisabled,
  ] = useState(false);

  const [invoiceEditProps, setInvoiceEditProps] = useState({
    isOpen: false,
    isLoading: false,
  });
  const [challanEditProps, setChallanEditProps] = useState({
    isOpen: false,
    isLoading: false,
  });

  const handleCopyClick = (e, text, setIsToolTipDisabled) => {
    setIsToolTipDisabled(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setIsToolTipDisabled(false);
    }, 1000);
  };

  const handleUpdateData = () => {
    fetchOrderDetails();
  };

  useEffect(() => {
    updateTitle(`${orderDetails?.customer?.name} : Order Details`);
  }, [isTitleUpdated, updateTitle, orderDetails]);
  const getOrderCustomerSupplierAssociationBranch = useMemo(() => {
    const customerAssociationBranch =
      orderDetails.customer?.supplierAssociations?.find(
        (supplierAssociation) => {
          return supplier?._id === supplierAssociation?.supplierId;
        }
      );

    return customerAssociationBranch;
  }, [orderDetails, supplier]);

  const getSupplierBranchName = useCallback(
    (branchId) => {
      const branch = allAvailableBranches?.find(
        (branch) => branch?._id === branchId
      );
      return branch ? branch?.branchName : "";
    },
    [orderDetails, allAvailableBranches]
  );

  /* The values will change once data is being fetched */
  const tableInfoObj = [
    {
      title: intl.formatMessage(messages.orderNumber),
      key: "orderNo",
      value: `${
        orderDetails?.orderType === "RETURN" ? "RT-" : ""
      }${orderDetails?.orderNo?.split("-").pop()}`,
    },
    {
      title: intl.formatMessage(messages.orderCreatedAt),
      value: !orderNotCreated
        ? moment(orderDetails?.createdAt).format("hh:mm:ss a, Do,MMM YYYY")
        : "",
    },
    {
      title: intl.formatMessage(messages.customerName),
      key: "customerName",
      value:
        orderDetails?.transactionType === TRANSACTIONTYPES.TRADING
          ? "Svan Autotech"
          : orderDetails?.customer?.name,
      isXpressCustomer: orderDetails?.customer?.isXpress,
    },
    {
      title: intl.formatMessage(messages.garaazCode),
      value:
        orderDetails?.transactionType === TRANSACTIONTYPES.TRADING
          ? "NA"
          : orderDetails?.customer?.code,
    },
    {
      title: intl.formatMessage(messages.supplierCode),
      key: "supplierCode",
      value:
        orderDetails?.transactionType === TRANSACTIONTYPES.TRADING
          ? "NA"
          : orderDetails?.customer?.supplierAssociations?.[0]?.supplierCode,
    },
    {
      title: intl.formatMessage(messages.contactNumber),
      value:
        orderDetails?.transactionType === TRANSACTIONTYPES.TRADING
          ? "9929219999"
          : orderDetails?.customer?.mobileNumber,
    },
    {
      title: intl.formatMessage(messages.workshopAddress),
      value: (() => {
        const firstAddress = orderDetails?.shipmentAddress;
        return orderDetails?.transactionType === TRANSACTIONTYPES.TRADING
          ? "E-28,2ND FLOOR,GAYATRI PLAZA, TRANSPORT NAGAR JAIPUR 302017"
          : firstAddress
          ? `${firstAddress?.addressLine1 || ""}${
              firstAddress?.addressLine2
                ? `, ${firstAddress?.addressLine2}`
                : ""
            }${firstAddress?.city ? `, ${firstAddress?.city}` : ""}${
              firstAddress?.state ? `, ${firstAddress?.state}` : ""
            }${firstAddress?.pinCode ? `, ${firstAddress?.pinCode}` : ""}`
          : "";
      })(),
    },
    {
      title: intl.formatMessage(messages.branchName),
      value: (() =>
        getSupplierBranchName(orderDetails?.allocatedTo?.[0]?.branchId))(),
    },
    {
      key: "invoiceDetails",
      title: intl.formatMessage(messages.invoiceDetails),
      value: orderDetails?.allocatedTo?.[0].invoiceInfo,
    },
    {
      key: "challanDetails",
      title: intl.formatMessage(messages.challanDetails),
      value: orderDetails?.allocatedTo?.[0].challanInfo,
    },
  ];

  if (allAvailableBranches?.length !== 0) {
    tableInfoObj.splice(5, 0, {
      key: "Association",
      title: intl.formatMessage(messages.association),
      value:
        orderDetails?.transactionType === TRANSACTIONTYPES.TRADING
          ? "NA"
          : getSupplierBranchName(
              getOrderCustomerSupplierAssociationBranch?.supplierBranchId
            ),
    });
  }
  if (orderDetails.orderType === "RETURN") {
    tableInfoObj.push({
      key: "parentOrder",
      title: intl.formatMessage(messages.parentOrder),
      value: parentOrderDetails?.orderNo ?? "",
    });
  }

  const tableInfoArr = tableInfoObj.map((item, index) => {
    const num = index + 1;
    const isOdd = num % 2 !== 0;

    if (item.key === "customerName") {
      return (
        <SingleOrderInfo
          title={item.title}
          value={
            <>
              <XpressWorkshop
                name={item.value}
                isXpressCustomer={item.isXpressCustomer}
              />
            </>
          }
          key={item.title}
          isOdd={isOdd}
        />
      );
    }

    if (item.key === "orderNo") {
      return (
        <SingleOrderInfo
          title={item.title}
          value={
            <Box display="flex">
              <Text>{item.value}</Text>
              <Tooltip
                border={"0.4px solid grey"}
                borderRadius={"5px"}
                bg={"white"}
                isOpen={isToolTipDisabled}
                label={
                  <Text color={"green"} display={"flex"} alignItems={"center"}>
                    <AiFillCheckCircle
                      fontSize={"1.3em"}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Order No. copied !
                  </Text>
                }
              >
                <Text
                  onClick={(e) => {
                    handleCopyClick(
                      e,
                      orderDetails?.orderNo,
                      setIsToolTipDisabled
                    );
                  }}
                  color={"black"}
                  cursor={"pointer"}
                  fontSize={"x-small"}
                  height={"fit-content"}
                  mt={1}
                  ml={1}
                >
                  <FaRegCopy />
                </Text>
              </Tooltip>
            </Box>
          }
          key={item.title}
          isOdd={isOdd}
        />
      );
    }

    if (item.key === "supplierCode") {
      return (
        <SingleOrderInfo
          title={item.title}
          value={
            <Box display="flex">
              <Text>{item.value}</Text>
              <Tooltip
                border={"0.4px solid grey"}
                borderRadius={"5px"}
                bg={"white"}
                isOpen={isSupplierCodeCopiedToolTipDisabled}
                label={
                  <Text color={"green"} display={"flex"} alignItems={"center"}>
                    <AiFillCheckCircle
                      fontSize={"1.3em"}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Supplier Code copied !
                  </Text>
                }
              >
                <Text
                  onClick={(e) => {
                    handleCopyClick(
                      e,
                      item.value,
                      setIsSupplierCodeCopiedTooltipDisabled
                    );
                  }}
                  color={"black"}
                  cursor={"pointer"}
                  fontSize={"x-small"}
                  height={"fit-content"}
                  mt={1}
                  ml={1}
                >
                  <FaRegCopy />
                </Text>
              </Tooltip>
            </Box>
          }
          key={item.title}
          isOdd={isOdd}
        />
      );
    }

    if (item.key === "invoiceDetails") {
      return (
        <SingleOrderInfo
          title={item.title}
          value={
            item.value ? (
              <Box>
                <UnorderedList styleType={"none"} m={0}>
                  <ListItem display={"flex"}>
                    <Text>Invoice No : {item.value?.invoiceNo} </Text>
                    <Tooltip
                      border={"0.4px solid grey"}
                      borderRadius={"5px"}
                      bg={"white"}
                      isOpen={isInvoiceNoCopiedToolTipDisabled}
                      label={
                        <Text
                          color={"green"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <AiFillCheckCircle
                            fontSize={"1.3em"}
                            style={{ marginRight: "5px" }}
                          />{" "}
                          Invoice No. copied !
                        </Text>
                      }
                    >
                      <Text
                        onClick={(e) => {
                          handleCopyClick(
                            e,
                            item.value?.invoiceNo,
                            setIsInvoiceNoCopiedToolTipDisabled
                          );
                        }}
                        color={"black"}
                        cursor={"pointer"}
                        fontSize={"x-small"}
                        height={"fit-content"}
                        mt={1}
                        ml={1}
                      >
                        <FaRegCopy />
                      </Text>
                    </Tooltip>
                  </ListItem>
                  <ListItem>Invoice Date : {item.value?.invoiceDate}</ListItem>
                  <ListItem>
                    Invoice Amount : {item.value?.invoiceAmount}
                  </ListItem>
                  <ListItem>No of Items : {item?.value?.noOfItems}</ListItem>
                  <ListItem>
                    Total Qty : {item?.value?.totalQtyOfItems}
                  </ListItem>
                </UnorderedList>
                <UpdateInvoiceModal
                  invoiceEditProps={invoiceEditProps}
                  setInvoiceEditProps={setInvoiceEditProps}
                  orderId={orderDetails._id}
                  orderDetails={orderDetails}
                  updateData={handleUpdateData}
                  challanEditProps={challanEditProps}
                  setChallanEditProps={setChallanEditProps}
                  openingFileType={openingFileTypeInvoiceModal}
                ></UpdateInvoiceModal>
              </Box>
            ) : (
              <>
                <Text>No Invoice Uploaded </Text>
                <UpdateInvoiceModal
                  invoiceEditProps={invoiceEditProps}
                  setInvoiceEditProps={setInvoiceEditProps}
                  orderId={orderDetails._id}
                  orderDetails={orderDetails}
                  updateData={handleUpdateData}
                  challanEditProps={challanEditProps}
                  setChallanEditProps={setChallanEditProps}
                  openingFileType={openingFileTypeInvoiceModal}
                ></UpdateInvoiceModal>
              </>
            )
          }
          key={item.title}
          isOdd={isOdd}
          setinvoiceeditprops={setInvoiceEditProps}
          setOpeningFileTypeInvoiceModal={setOpeningFileTypeInvoiceModal}
        />
      );
    }
    if (item.key === "challanDetails") {
      return (
        <SingleOrderInfo
          title={item.title}
          value={
            item.value ? (
              <Box>
                <UnorderedList styleType={"none"} m={0}>
                  <ListItem display={"flex"}>
                    <Text>Challan No : {item.value?.challanNo} </Text>
                    <Tooltip
                      border={"0.4px solid grey"}
                      borderRadius={"5px"}
                      bg={"white"}
                      isOpen={isChallanNoCopiedToolTipDisabled}
                      label={
                        <Text
                          color={"green"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <AiFillCheckCircle
                            fontSize={"1.3em"}
                            style={{ marginRight: "5px" }}
                          />{" "}
                          Challan No. copied !
                        </Text>
                      }
                    >
                      <Text
                        onClick={(e) => {
                          handleCopyClick(
                            e,
                            item.value?.challanNo,
                            setIsChallanNoCopiedToolTipDisabled
                          );
                        }}
                        color={"black"}
                        cursor={"pointer"}
                        fontSize={"x-small"}
                        height={"fit-content"}
                        mt={1}
                        ml={1}
                      >
                        <FaRegCopy />
                      </Text>
                    </Tooltip>
                  </ListItem>
                  <ListItem>Challan Date : {item.value?.challanDate}</ListItem>
                  <ListItem>
                    Challan Amount : {item.value?.challanAmount}
                  </ListItem>
                </UnorderedList>
              </Box>
            ) : (
              <>
                <Text>No challan Uploaded</Text>
              </>
            )
          }
          key={item.title}
          isOdd={isOdd}
          setChallanEditProps={setChallanEditProps}
          setinvoiceeditprops={setInvoiceEditProps}
          setOpeningFileTypeInvoiceModal={setOpeningFileTypeInvoiceModal}
        />
      );
    }

    if (item.key === "parentOrder") {
      return (
        <SingleOrderInfo
          title={item.title}
          value={
            <Box display="flex">
              <Text>{item.value}</Text>
              {item.value !== "" && (
                <Tooltip
                  border={"0.4px solid grey"}
                  borderRadius={"5px"}
                  bg={"white"}
                  isOpen={isparentOrderNoCopiedToolTipDisabled}
                  label={
                    <Text
                      color={"green"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <AiFillCheckCircle
                        fontSize={"1.3em"}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Parent Order No. copied !
                    </Text>
                  }
                >
                  <Text
                    onClick={(e) => {
                      handleCopyClick(
                        e,
                        parentOrderDetails?.orderNo,
                        setIsParentOrderNoCopiedToolTipDisabled
                      );
                    }}
                    color={"black"}
                    cursor={"pointer"}
                    fontSize={"x-small"}
                    height={"fit-content"}
                    mt={1}
                    ml={1}
                  >
                    <FaRegCopy />
                  </Text>
                </Tooltip>
              )}
            </Box>
          }
          key={item.title}
          isOdd={isOdd}
        />
      );
    }

    return (
      <SingleOrderInfo
        title={item.title}
        value={item.value}
        key={item.title}
        isOdd={isOdd}
      />
    );
  });

  return (
    <Box>
      <Box
        mb={10}
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        px={4}
      >
        <Box
          flex={1}
          justifyContent="space-between"
          display="flex"
          flexDirection={"column"}
        >
          {tableInfoArr}
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"50%"}
        >
          {orderDetails?.customerRemarks && (
            <Remarks
              key="remarks"
              title="Remarks"
              value={orderDetails?.customerRemarks}
            />
          )}
          <Remarks
            key={"orderRemarks"}
            title={"Order Remarks"}
            value={orderDetails?.allocatedTo?.[0]?.orderRemarks ?? ""}
          ></Remarks>
        </Box>
      </Box>
    </Box>
  );
};

const SingleOrderInfo = ({
  title,
  value,
  index,
  isOdd,
  setinvoiceeditprops,
  setChallanEditProps,
  setOpeningFileTypeInvoiceModal,
}) => {
  const { colorMode } = useColorMode();
  const intl = useIntl();

  return (
    <Box
      display="flex"
      flexBasis={{ base: "100%", lg: "48%" }}
      justifyContent="space-between"
      bg={
        colorMode === "dark" && isOdd
          ? "black"
          : isOdd && colorMode === "light"
          ? "#F7FAFC"
          : ""
      }
      p={2}
      pb={{ base: 4, lg: 2 }}
    >
      <Box width="30%" color="#8A98A9" fontWeight="bold" fontSize={"sm"}>
        {title}
        {(title === intl.formatMessage(messages.invoiceDetails) ||
          title === intl.formatMessage(messages.challanDetails)) && (
          <IconButton
            ml={2}
            width={7}
            height={7}
            minW={7}
            onClick={() => {
              setOpeningFileTypeInvoiceModal(
                title === intl.formatMessage(messages.invoiceDetails)
                  ? "Invoice"
                  : "Challan"
              );
              setinvoiceeditprops((prevState) => ({
                ...prevState,
                isOpen: true,
              }));

              setChallanEditProps((prevState) => ({
                ...prevState,
                isOpen: true,
              }));
            }}
          >
            <MdAddChart />
          </IconButton>
        )}
      </Box>
      <Box width="60%" textAlign="left" layerStyle="dark" fontSize="xs">
        {value}
      </Box>
    </Box>
  );
};

const Remarks = ({ title, value, index, isOdd }) => {
  const [isOpen, setOpen] = useState(false);
  const { colorMode } = useColorMode();
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection={"column"}
      justifyContent={"flex-start"}
      bg={
        colorMode === "dark" && isOdd
          ? "black"
          : isOdd && colorMode === "light"
          ? "#F7FAFC"
          : ""
      }
      px={3}
    >
      <Text
        width="100%"
        color="#1E293B"
        fontWeight="bold"
        textStyle="normal"
        fontSize={"md"}
      >
        {title}
      </Text>
      <Box width="100%" flex={1} textStyle="normal" layerStyle="dark">
        <Text fontSize={"xs"} noOfLines={6}>
          {value}
        </Text>
        {value.length > 450 && (
          <Button
            onClick={() => setOpen(true)}
            variant="link"
            color="blue"
            size="xs"
          >
            view more
          </Button>
        )}
      </Box>
      <SimpleModal
        onClose={() => setOpen(false)}
        isOpen={isOpen}
        title={title}
        hidefooter
      >
        <Box textStyle="normal" layerStyle="dark">
          {value}
        </Box>
      </SimpleModal>
    </Box>
  );
};
