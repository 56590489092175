import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

import {
  Box,
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import TableTopNew from "../../components/TableTop/TableTopNew";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllOrders,
  setAllOrders,
  setLastApiCallTime,
  setTotalCount,
  updateTableTopValue,
} from "./actions";
import DynamicTableNew from "../../components/DynamicTableNew";
import enums, {
  getSupplierStateColor,
  TRANSACTIONTYPES,
} from "../../utils/enums";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import garaazToast from "../../HOCs/garaazToast";
import RequestOrder from "../OrderDetailsNew/RequestOrder";
import { selectGlobalState, updateActionTaken } from "../../globalSlice";
import UpdateInvoiceModal from "../OrderDetailsNew/updateInvoiceModal";
import ActionCell from "./ActionCell";
import DateTimeOrder from "../../components/OrderComponentsNew/DateTimeOrder";
import XpressCustomerNew from "../../components/WorkShopList/XpressCustomerNew";
import { dateFormatForExport } from "../../utils/checkDates";
import { checkIsBefore, checkIsSame } from "../../utils/checkDates";
import { registerSupplierAction } from "../OrderDetailsNew/actions";
import { FaRegCopy } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import GaraazCountDownTimer from "./GaraazCountDownTimer";
import { FaRupeeSign } from "react-icons/fa";
import SupplierOrderStatus from "../../utils/supplierOrderStatus";
import { PENDING_SUPPLIER_ORDER_STATUS } from "./constants";

const OrderListNew = ({ notification }) => {
  const intl = useIntl();
  const [seed, setSeed] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const auth = useSelector((state) => state.auth);

  const {
    orders,
    totalCount,
    tableTopValues: {
      orderNo,
      supplierOrderStatus,
      branchValue,
      selectedCustomer,
      dates,
      isPending,
    },
    lastApiCallTime,
  } = useSelector((state) => state.orderListNew);
  const dispatcher = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { path } = useRouteMatch();
  const [pageCount, setPageCount] = useState(0);
  const { allAvailableBranches, isDistributor, toggleActionTaken } =
    useSelector(selectGlobalState);
  const [exportData, setExportData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const csvInstance = useRef(null);

  const [invoiceEditProps, setInvoiceEditProps] = useState({
    isOpen: false,
    isLoading: false,
  });

  const [challanEditProps, setChallanEditProps] = useState({
    isOpen: false,
    isLoading: false,
  });

  const [params, setParams] = useState({});

  useEffect(() => {
    if (exportData.length > 0) {
      csvInstance.current.link.click();
      setExportData([]);
    }
  }, [exportData]);

  const getSupplierBranchName = useCallback(
    (branchId) => {
      const branch = allAvailableBranches?.find(
        (branch) => branch?._id === branchId
      );
      return branch ? branch?.branchName : "";
    },
    [orders, allAvailableBranches]
  );

  const fetchAllOrders = (params) => {
    (async () => {
      setIsLoading(true);
      try {
        const back = sessionStorage.back;
        const custPageIndex = sessionStorage.pageIndex;
        if (back && custPageIndex && parseInt(JSON.parse(custPageIndex))) {
          sessionStorage.removeItem("pageIndex");
          sessionStorage.removeItem("back");
        }

        const response = await getAllOrders(auth, params);

        setParams(params);
        setTableData(response?.orders);

        dispatcher(setAllOrders(response?.data));

        params.limit = params?.limit ? params?.limit : 150;
        setPageCount(Math.ceil(response?.count / params?.limit));

        dispatcher(setTotalCount(response?.count));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          notification({
            status: "error",
            description:
              error.response.data.message ??
              intl.formatMessage(messages.errorMessage),
          });
        }
      }
    })();
  };

  const getLatestOrders = () => {
    const currentTime = new Date().getTime();
    const timeElapsed = Number(currentTime) - Number(lastApiCallTime);

    if (timeElapsed >= 60000) {
      const orderStatusString = isPending
        ? generateOrderStatusString(PENDING_SUPPLIER_ORDER_STATUS)
        : generateOrderStatusString(supplierOrderStatus);
      fetchAllOrders({
        ...params,
        supplierOrderStatus: orderStatusString,
        customerId: selectedCustomer?.value,
        branchId: branchValue?.value,
        allocationStartDate: dates.startDate,
        allocationEndDate: dates.endDate,
        isPopulated: true,
      });
      dispatcher(setLastApiCallTime(currentTime));
      dispatcher(updateActionTaken(!toggleActionTaken));
    }
  };

  useEffect(() => {
    window.addEventListener("focus", getLatestOrders);
    return () => {
      window.removeEventListener("focus", getLatestOrders);
    };
  }, [
    dates.startDate,
    dates.endDate,
    supplierOrderStatus,
    branchValue,
    selectedCustomer,
    orderNo,
    lastApiCallTime,
  ]);

  async function updateIsAcknowledged(orderId) {
    let payload = {};

    await registerSupplierAction({
      auth,
      orderId,
      action: enums.ACK,
    });
    const updatedOrders = tableData?.map((order) => {
      if (order._id === orderId) {
        payload = {
          allocatedTo: [{ ...order?.allocatedTo[0], orderStatus: enums.ACK }],
        };
        return { ...order, ...payload };
      } else {
        return order;
      }
    });
    setTableData(updatedOrders);
    dispatcher(setAllOrders(updatedOrders));
  }

  const fetchExportData = async () => {
    setExportLoading(true);

    try {
      const response = await getAllOrders(auth, {
        ...params,
        limit: totalCount,
        offset: 0,
      });
      setExportLoading(false);

      const refinedTableData = response.orders.map((data) => {
        const orderStatus = data?.allocatedTo?.[0]?.orderStatus;
        const stateTime = new Date(data?.allocatedTo?.[0]?.stateTime);
        const allocationTime = new Date(data?.allocatedTo?.[0]?.allocationTime);
        const actionTime = stateTime.getTime() - allocationTime.getTime();
        let SLA = "False";

        if (
          SupplierOrderStatus.isActionTaken(orderStatus) &&
          actionTime <= 30 * 60 * 1000
        )
          SLA = "True";

        return {
          Date: dateFormatForExport(data?.allocatedTo?.[0]?.allocationTime),
          "Order NO": data?.orderNo,
          SLA: SLA,
          "Customer Code": data?.customer?.code,
          "Customer Name": data?.customer?.name,
          "Supplier Code":
            data?.customer?.supplierAssociations?.[0]?.supplierCode,
          "Total Part": data?.orderItems.length,
          "Branch Name": getSupplierBranchName(
            data?.allocatedTo?.[0]?.branchId
          ),

          "Invoice Amount": data?.invoiceInfo?.invoiceAmount,
          "Invoice No.": data?.invoiceInfo?.invoiceNo,
          "Order Status": data?.orderStatus,
        };
      });
      setExportData(refinedTableData);
    } catch (error) {
      setExportLoading(false);
    }
  };

  const garaazOrderStatusOption = useMemo(
    () => [
      {
        value: enums.NEW,
        label: intl.formatMessage(messages.NEW),
      },
      {
        value: enums.CBO,
        label: intl.formatMessage(messages.CBO),
      },
      {
        value: enums.CANCELLED,
        label: intl.formatMessage(messages.CANCELLED),
      },
      {
        value: enums.READY,
        label: intl.formatMessage(messages.READY),
      },
      {
        value: enums.ACK,
        label: intl.formatMessage(messages.ACK),
      },
      {
        value: enums.REVOKED,
        label: intl.formatMessage(messages.REVOKED),
      },
    ],
    []
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: intl.formatMessage(messages.date),
        accessor: (row) => [
          {
            createdAt: row?.allocatedTo?.[0]?.allocationTime ?? row.createdAt,
            orderStatus: row?.allocatedTo?.[0]?.orderStatus,
            stateTime: row?.allocatedTo?.[0]?.stateTime,
          },
        ],
        Cell: ({ value }) => (
          <Box display={"flex"} width={"100%"} justifyContent={"center"}>
            <Tooltip label={value[0].orderStatus} placement="right-start">
              <Box
                width={"6px"}
                bgColor={getSupplierStateColor(value[0].orderStatus).color}
                position={"absolute"}
                top={0}
                left={0}
                right={0}
                bottom={0}
              ></Box>
            </Tooltip>

            <Box px={2} py={2} justifyContent={"center"}>
              <DateTimeOrder dateandtime={value[0].createdAt} />
            </Box>
          </Box>
        ),
      },
      {
        Header: intl.formatMessage(messages.orderNo),
        accessor: (row) => ({
          orderNo: row.orderNo,
          orderType: row.orderType,
          orderStatus: row?.allocatedTo?.[0]?.orderStatus,
        }),
        Cell: ({ value }) => {
          const [isToolTipDisabled, setIsToolTipDisabled] = useState(false);
          return (
            <Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text fontSize={"sm"}>{`${
                  value.orderType === "RETURN" ? "RT-" : ""
                }${value.orderNo.split("-").pop()}`}</Text>
                <Tooltip
                  border={"0.4px solid grey"}
                  borderRadius={"5px"}
                  bg={"white"}
                  isOpen={isToolTipDisabled}
                  label={
                    <Text
                      color={"green"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <AiFillCheckCircle
                        fontSize={"1.3em"}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Order No. copied !
                    </Text>
                  }
                >
                  <Text
                    onClick={(e) => {
                      setIsToolTipDisabled(true);
                      navigator.clipboard.writeText(value.orderNo);
                      setTimeout(() => {
                        setIsToolTipDisabled(false);
                      }, 1000);
                    }}
                    color={"black"}
                    cursor={"pointer"}
                    fontSize={"x-small"}
                    height={"fit-content"}
                    mt={1}
                    ml={1}
                  >
                    <FaRegCopy />
                  </Text>
                </Tooltip>
                <Badge
                  backgroundColor={"white"}
                  ml={2}
                  px={1}
                  py={0.5}
                  height={"fit-content"}
                  color={getSupplierStateColor(value.orderStatus).color}
                  fontSize={"0.5rem"}
                  border={`1px solid ${
                    getSupplierStateColor(value.orderStatus).color
                  }`}
                  lineHeight={"normal"}
                >
                  {value.orderStatus}
                </Badge>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: intl.formatMessage(messages.SLA),
        accessor: (row) => ({
          orderStatus: row?.allocatedTo?.[0]?.orderStatus,
          allocationTime: row?.allocatedTo?.[0]?.allocationTime,
          stateTime: row?.allocatedTo?.[0]?.stateTime,
        }),
        Cell: ({ value }) => {
          return (
            <GaraazCountDownTimer
              allocationTime={value.allocationTime}
              orderStatus={value.orderStatus}
              stateTime={value.stateTime}
            ></GaraazCountDownTimer>
          );
        },
      },
      {
        Header: intl.formatMessage(messages.customerName),
        accessor: (row) => [
          {
            name:
              row?.transactionType === TRANSACTIONTYPES.TRADING
                ? "Svan Autotech"
                : row?.customer?.name,
            isXpressCustomer:
              row?.transactionType === TRANSACTIONTYPES.TRADING
                ? true
                : row?.customer.isXpress,
          },
        ],
        Cell: ({ value }) => {
          return (
            <XpressCustomerNew
              name={value[0]?.name}
              isXpressCustomer={value[0]?.isXpressCustomer}
            />
          );
        },
      },
      {
        Header: intl.formatMessage(messages.invoiceInfo),
        accessor: (row) => ({
          invoiceNo: row.allocatedTo?.[0]?.invoiceInfo?.invoiceNo ?? "",
          invoiceAmount: row.allocatedTo?.[0]?.invoiceInfo?.invoiceAmount ?? "",
        }),
        Cell: ({ value }) => {
          return (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Text>{value.invoiceNo}</Text>
              {value.invoiceAmount && (
                <Box display={"flex"} alignItems={"center"}>
                  <Text fontSize={"xs"}>
                    <FaRupeeSign></FaRupeeSign>{" "}
                  </Text>
                  <Text fontSize={"sm"}> {value.invoiceAmount}</Text>
                </Box>
              )}
            </Box>
          );
        },
      },

      {
        Header: intl.formatMessage(messages.orderRemarks),
        accessor: (row) => row.allocatedTo?.[0]?.orderRemarks,
        Cell: ({ value }) => {
          const { isOpen, onOpen, onClose } = useDisclosure();
          return (
            <Box display={"flex"} justifyContent={"center"}>
              <Text
                width={"fit-content"}
                noOfLines={1}
                onClick={onOpen}
                cursor={"pointer"}
              >
                {value}
              </Text>
              <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Order Remarks</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text>{value}</Text>
                  </ModalBody>

                  <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
          );
        },
      },
      {
        Header: intl.formatMessage(messages.actions),
        accessor: (row) => row.orderNo,
        Cell: ActionCell,
      },
    ];

    if (isDistributor && allAvailableBranches?.length > 0) {
      baseColumns.splice(3, 0, {
        Header: intl.formatMessage(messages.branchName),
        accessor: (row) =>
          getSupplierBranchName(row?.allocatedTo?.[0]?.branchId),
      });
    }

    return baseColumns;
  }, [isDistributor, allAvailableBranches]);

  const generateOrderStatusString = useCallback((statusList) => {
    if (Array.isArray(statusList)) {
      const orderStatusValues = statusList.map(
        (status) => status.value ?? status
      );
      return orderStatusValues.join("&supplierOrderStatus=");
    } else if (typeof statusList === "string") {
      return statusList.split(",").join("&supplierOrderStatus=");
    } else {
      return "";
    }
  }, []);

  const handleSelectChange = (valueList, name) => {
    const orderStatusString = generateOrderStatusString(valueList);
    const orderStatusString2 = isPending
      ? generateOrderStatusString(PENDING_SUPPLIER_ORDER_STATUS)
      : generateOrderStatusString(supplierOrderStatus);

    if (name === enums.STATUS) {
      dispatcher(updateTableTopValue({ supplierOrderStatus: valueList }));
      fetchAllOrders({
        ...params,
        supplierOrderStatus: orderStatusString,
        orderNo: orderNo,
        customerId: selectedCustomer?.value,
        branchId: branchValue?.value,
        allocationStartDate: dates.startDate,
        allocationEndDate: dates.endDate,
        isPopulated: true,
      });
    } else if (name === enums.WORKSHOP) {
      dispatcher(updateTableTopValue({ selectedCustomer: valueList }));
      fetchAllOrders({
        ...params,
        customerId: selectedCustomer?.value,
        orderNo: orderNo,
        branchId: branchValue?.value,
        supplierOrderStatus: orderStatusString2,
        allocationStartDate: dates.startDate,
        allocationEndDate: dates.endDate,
        isPopulated: true,
      });
    } else if (name === enums.BRANCH) {
      dispatcher(updateTableTopValue({ branchValue: valueList }));
      fetchAllOrders({
        ...params,
        branchId: valueList?.value,
        orderNo: orderNo,
        customerId: selectedCustomer?.value,
        supplierOrderStatus: orderStatusString2,
        allocationStartDate: dates.startDate,
        allocationEndDate: dates.endDate,
        isPopulated: true,
      });
    }
  };

  useEffect(() => {
    if (isReset) {
      setSeed(Math.random());
      setIsReset(false);
    }
  }, [isReset]);

  const reset = () => {
    setTableData([], setIsReset(true));
  };

  const handleSearchById = (event) => {
    const idString = event.target.value;
    let orderStatusString;
    if (isPending) {
      orderStatusString = generateOrderStatusString(
        PENDING_SUPPLIER_ORDER_STATUS
      );
    } else {
      orderStatusString = generateOrderStatusString(supplierOrderStatus);
    }
    dispatcher(updateTableTopValue({ orderNo: `${idString}`.trim() }));
    fetchAllOrders({
      supplierOrderStatus: orderStatusString,
      orderNo: `${idString}`.trim(),
      customerId: selectedCustomer?.value,
      branchId: branchValue?.value,
      allocationStartDate: dates.startDate,
      allocationEndDate: dates.endDate,
      isPopulated: true,
    });
  };

  const branchOptions = useMemo(() => {
    return allAvailableBranches?.map((branch) => ({
      label: branch.branchName,
      value: branch._id,
    }));
  }, [allAvailableBranches]);

  const handleDateChange = (event) => {
    let { value, name } = event.target;

    let startDate = "";
    let endDate = "";
    const orderStatusString = isPending
      ? generateOrderStatusString(PENDING_SUPPLIER_ORDER_STATUS)
      : generateOrderStatusString(supplierOrderStatus);

    if (name === enums.STARTDATE && value) {
      startDate = value;
      if (
        !checkIsBefore(value, dates.endDate) &&
        !checkIsSame(value, dates.endDate)
      ) {
        notification({
          status: "error",
          description: intl.formatMessage(messages.dateError),
        });
        return;
      }
    }

    if (name === enums.ENDDATE && value) {
      endDate = value;
      if (
        checkIsBefore(value, dates.startDate) &&
        !checkIsSame(value, dates.startDate)
      ) {
        notification({
          status: "error",
          description: intl.formatMessage(messages.dateError),
        });
        return;
      }
    }
    dispatcher(updateTableTopValue({ dates: { ...dates, [name]: value } }));
    fetchAllOrders({
      allocationStartDate: startDate || dates.startDate,
      allocationEndDate: endDate || dates.endDate,
      supplierOrderStatus: orderStatusString,
      orderNo: orderNo,
      customerId: selectedCustomer?.value,
      branchId: branchValue?.value,
      isPopulated: true,
    });
  };

  const handleCustomerChange = (options) => {
    dispatcher(updateTableTopValue({ selectedCustomer: options }));
    const orderStatusString = isPending
      ? generateOrderStatusString(PENDING_SUPPLIER_ORDER_STATUS)
      : generateOrderStatusString(supplierOrderStatus);
    fetchAllOrders({
      customerId: options ? options?.value : "",
      orderNo: orderNo,
      supplierOrderStatus: orderStatusString,
      allocationStartDate: dates.startDate,
      allocationEndDate: dates.endDate,
      branchId: branchValue?.value,
      isPopulated: true,
    });
  };

  const handleSupplierOrderStatusChange = (e) => {
    const checkedStatuses = PENDING_SUPPLIER_ORDER_STATUS;
    const statusesToUse = e.target.checked ? checkedStatuses : [];
    const orderStatusString = generateOrderStatusString(statusesToUse);
    dispatcher(
      updateTableTopValue({
        supplierOrderStatus: orderStatusString,
        isPending: e.target.checked,
      })
    );
    fetchAllOrders({
      customerId: selectedCustomer?.value,
      orderNo: orderNo,
      supplierOrderStatus: orderStatusString,
      allocationStartDate: dates.startDate,
      allocationEndDate: dates.endDate,
      branchId: branchValue?.value,
      isPopulated: true,
    });
  };

  const getPageIndex = (pageIndex) => {
    sessionStorage.setItem("pageIndex", JSON.stringify(pageIndex));
  };

  const updateMyData = () => {
    fetchAllOrders(params);
  };

  return (
    <Switch>
      <Route exact path={path}>
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <TableTopNew
            onSearchOrderChange={handleSearchById}
            onSelectChange={handleSelectChange}
            orderStatus={supplierOrderStatus}
            branchOptions={branchOptions}
            branchValue={branchValue}
            orderNo={orderNo}
            startDate={dates.startDate}
            endDate={dates.endDate}
            onDateChange={handleDateChange}
            handleCustomerChange={handleCustomerChange}
            statusOption={garaazOrderStatusOption}
            selectedCustomer={selectedCustomer}
            csvInstance={csvInstance}
            exportData={exportData}
            fetchExportData={fetchExportData}
            exportLoading={exportLoading}
            handleSupplierOrderStatusChange={handleSupplierOrderStatusChange}
            reset={reset}
          />

          <DynamicTableNew
            key={seed}
            columns={columns}
            data={tableData}
            fetchData={fetchAllOrders}
            totalCount={totalCount}
            controlledPageCount={pageCount}
            loading={isLoading}
            skipPageReset={true}
            updateMyData={updateMyData}
            customPageSize={150}
            customPageIndex={
              sessionStorage.pageIndex && sessionStorage.back
                ? parseInt(JSON.parse(sessionStorage.pageIndex))
                : null
            }
            getPageIndex={getPageIndex}
            params={{
              isPopulated: true,
              orderNo: orderNo,
              supplierOrderStatus:
                isPending === true
                  ? generateOrderStatusString(PENDING_SUPPLIER_ORDER_STATUS)
                  : generateOrderStatusString(supplierOrderStatus),
              branchId: branchValue?.value,
              customerId: selectedCustomer?.value,
              allocationStartDate: dates.startDate,
              allocationEndDate: dates.endDate,
            }}
            updateIsAcknowledged={updateIsAcknowledged}
          />
        </Box>
      </Route>
      <Route exact path={`${path}/:orderNo`}>
        <RequestOrder updateOrdersData={() => fetchAllOrders(params)} />
      </Route>
      <UpdateInvoiceModal
        invoiceEditProps={invoiceEditProps}
        setInvoiceEditProps={setInvoiceEditProps}
        challanEditProps={challanEditProps}
        setChallanEditProps={setChallanEditProps}
      />
    </Switch>
  );
};

export default garaazToast(OrderListNew);
