const enums = {
  STARTDATE: "startDate",
  ENDDATE: "endDate",
  STATUS: "status",
  WORKSHOP: "workshop",
  BRANCH: "branch",
  UPDATED: "UPDATED",
  COMPLETED: "COMPLETED",
  READY: "READY",
  PENDING: "PENDING",
  CANCEL_ORDER: "CANCEL_THE_ORDER",
  TRANSFER_ORDER: "TRANSFER_TO_ANOTHER_BRANCH",
  TRANSFER_STOCK: "STOCK_TRANSFER",
  BACK_ORDER: "CUSTOMER_BACK_ORDER",
  STOCK_TRANSFER_COMPLETED: "STOCK_TRANSFER_COMPLETED",
  STOCK_TRANSFER_PLACED: "STOCK_TRANSFER_PLACED",
  CBO: "CBO",
  NEW: "NEW",
  CANCELLED: "CANCELLED",
  LOST_SALE: "LOST_SALE",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
  IMAGE: "IMAGE",
  REQUEST_ORDER: "REQUEST_ORDER",
  REQUESTED: "REQUESTED",
  DELIVERED: "DELIVERED",
  APPROVED: "APPROVED",
  READY_TO_DISPATCH: "READY_TO_DISPATCH",
  IN_TRANSIT: "IN_TRANSIT",
  ORDERS: "Orders",
  ORDERS_NEW: "Orders New",
  PROFILE: "Profile",
  SUPPLIERS: "SUPPLIERS",
  EMPLOYEES: "Employees",
  PARTS: "Parts",
  WORKSHOPS: "Workshops",
  SALES: "Sales",
  INVENTORY: "Inventory",
  ACCOUNT: "Account",
  PLACE_ORDER: "Place Order",
  TRANSFER_STOCKS: "Transfer Stock",
  DATE_FORMAT: "YYYY-MM-DD",
  ENG: "en",
  HIN: "hi",
  ORDER_PLACED: "ORDER_PLACED",
  ORDER_UPDATED: "ORDER_UPDATED",
  ACK: "ACK",
  REVOKE: "REVOKE",
  REVOKED: "REVOKED",
  RETURN_REQUESTED: "RETURN_REQUESTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export function getSupplierStateColor(stateName) {
  switch (stateName) {
    case enums.NEW:
      return { color: "#92E6A7", textColor: "black" };
    case enums.ACK:
      return { color: "#FF8C00", textColor: "black" };
    case enums.CBO:
      return { color: "#936639", textColor: "white" };
    case enums.REVOKED:
      return { color: "#9932CC", textColor: "white" };
    case enums.READY:
      return { color: "#1A7431", textColor: "white" };
    case enums.CANCELLED:
      return { color: "#85182A", textColor: "white" };
    case enums.RETURN_REQUESTED:
      return { color: "#92E6A7", textColor: "black" };
    case enums.ACCEPTED:
      return { color: "#1A7431", textColor: "white" };
    case enums.REJECTED:
      return { color: "#E01E37", textColor: "white" };
  }
}

export const COMPONENTS = {
  SUPPLIER_WEB_APP: "SUPPLIER_WEB_APP",
};

export const TRANSACTIONTYPES = {
  TRADING: "Trading",
  FACILITATION: "Facilitation",
};

export const ROLES = {
  ADMIN: "ADMIN",
  EMPLOYEE: "EMPLOYEE",
  AUDITOR: "AUDITOR",
};

export const ROLE = {
  SUPPLIER: "SUPPLIER",
};

export default enums;
